//main: "../blog.less"

.blog {
	font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;

	h1{
		font-size: 30px;
		font-weight: 700;
		font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif !important;
	}

	.content {
		code {
			background-color: black;
			color: white;
			font-family: 'Courier New', Courier, monospace;
			display: block;
			border-radius: 10px;
			padding: 20px;
		}

		img {
			object-fit: contain;
			width: 100%;
			margin: 20px 0;
		}

		p{
			
			line-height: 32px;
			margin-top: 20px;
			font-size: 20px;
		}
		a{
			color: blue;
		}
	}
}