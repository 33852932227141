//main: "../main.less"

@keyframes gates-open {
	from {
		transform: rotateY(0deg);
	}

	to {
		transform: rotateY(90deg);
		visibility: hidden;
	}
}

@keyframes login-show {
	from {
		transform: rotateY(270deg);
	}

	to {
		transform: rotateY(360deg);
		display: block;
	}
}

.top-section {

	h1 {
		min-height: 1rem;
		font-size: 2rem;
	}

	.intro-image-block {

		margin-bottom: 5px;

		&__image {
			margin: 0 auto;
		}
	}

	.intro-text-block {
		&__text {
			font-weight: 700;
		}
	}

	.login {
		width: 300px;
		height: 325px;
		background-color: black;
		border: 2px dashed white;
		outline: 5px solid white;
		position: absolute;
		top: 0;
		left: calc(50% - 150px);
		transform: rotateY(270deg);

		.login-form {
			display: none;

			.input-block {
				.form-control-input{
					padding: 0 5px;
				}

				.label{
					margin-right: 5px;
				}

				&:last-child {
					margin-top: 10px;
				}
			}

			.btn {
				background-color: white;
				background-image: none;
				border-radius: 5px;
				padding: 5px 15px;
				border: 2px solid gray;
			}
		}

		&--animated {
			animation-name: login-show;
			animation-duration: 1s;
			animation-delay: 1s;
			animation-fill-mode: forwards;

			.login-form {
				display: block;

				position: relative;
				top: 25px;

				.recapcha {
					margin-top: 10px;
					display: flex;
					justify-content: center;
				}

				.form-control-input {
					width: 150px;
				}

				.label {
					color: white;
				}

				.login-password-block {
					display: flex;
					flex-direction: column;
					align-items: end;
					position: relative;
					right: 50px;
				}

				.text-danger {
					display: block;
				}

				.btn:hover {
					cursor: pointer;
				}
			}
		}
	}

	.logged-in-form {
		color: white;

		top: 100px;
		position: relative;
		display: flex;
		flex-direction: column;

		.button-row {
			position: relative;
			top: 70px;
		}

		.link-to-blog {
			color: blue;
			margin-top: 10px;
		}
	}
}