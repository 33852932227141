//main: "../main.less"

.about-me-section {

	h4 {
		line-height: 1.28571429em;
		margin: calc(2rem - 0.14285714em) 0 1rem;
		font-weight: 700;
		padding: 0;
	}

	p {
		margin: 0 0 1em;
		line-height: 1.4285em;
	}

	ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	}

	:not(:last-child).technology-icon {
		margin-right: 5px;
	}

	.technology-icon {
		background-repeat: no-repeat;
		display: inline-block;
		width: 1rem;
		height: 1rem;
		margin-left: 5px;
		background-position: center;
		background-size: contain;
	}

	.node-js {
		background-image: url('../../public/images/node-js_30x30.png');
	}

	.python {
		background-image: url('../../public/images/python_30x30.png');
	}

	.solidity {
		background-image: url('../../public/images/solidity_30x30.png');
	}
}