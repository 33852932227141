//main: "../blog.less"

#root {
	height: 100%;
}

.blog {

	min-height: calc(100% - 170px);

	.pf-block {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.articles-list {
		margin-top: 20px;
	}

	.add-article { 
		display: flex;
		justify-content: right;

		&__button {
			background: url('../../public/images/add-icon_50x50.png') no-repeat;
			background-size: cover;
			height: 50px;
			width: 50px;
			display: block;
		}
	}

	.create-button-row {
		text-align: right;
		padding: 20px 0;
	}

	.articles-list {

		.article {
			flex-basis: 300px;
			justify-content: space-between;
			align-items: stretch;
			display: flex;
			font-size: 18px;

			&:hover .header {
				text-shadow: 1px 1px black;
			}

			&-footer {
				display: flex;

				.article-date {
					margin-left: auto;
				}
			}

			.input-block {
				display: flex;

				.label {
					width: 100px;
					text-align: right;
					display: block;
				}

				.form-control-input,
				.form-control-text-area {
					margin-left: 20px;
					width: 70%;
				}
			}
		}

		.article.article--published {
			background-color: aliceblue;
		}
	}

	.article-date{
		background-color: gainsboro;
		display: inline-block;
		padding: 5px;
		border-radius: 5px;
	}

	.content {
		font-size: 22px;
		margin-top: 30px;
	}
}