// sourceMap: true, compress: true, out: ./main.css
 
@-webkit-keyframes drop {
	0% {
		top: 0px;
		opacity: 0;
	}

	30% {
		top: 10px;
		opacity: 1;
	}

	100% {
		top: 25px;
		opacity: 0;
	}
}

@keyframes drop {
	0% {
		top: 0px;
		opacity: 0;
	}

	30% {
		top: 10px;
		opacity: 1;
	}

	100% {
		top: 25px;
		opacity: 0;
	}
}

:root {
	font-size: 16px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	height: 100%;
}

body {

	line-height: 1.42rem;
	margin: 0;
	padding: 0;

	h2 {
		font-size: 2rem;
	}

	a {
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
		cursor: pointer;
	}

	a.scroll-anchor {
		color: white;
	}

	.btn-primary {
		color: #76818d;
		background-color: #fff;
		border: 1px solid;
		border-radius: 4px;
		border-color: #76818d;
		box-shadow: none;
		padding: 6px 12px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}

	.btn-primary:hover,
	.btn-primary:active {
		color: #eee;
		background-color: #2c3e50;
		border-color: #2c3e50;
	}

	.btn-primary:focus,
	.btn-primary.focus,
	.btn-primary.active,
	.btn-primary:active,
	a:link {
		box-shadow: none;
	}

	.btn-primary i.fa {
		margin-right: 10px;
	}

	.btn-primary:not(:disabled):not(.disabled):active,
	.btn-primary:not(:disabled):not(.disabled).active,
	.show>.btn-primary.dropdown-toggle {
		color: #eee;
		background-color: #2c3e50;
		border-color: #2c3e50;
		box-shadow: none;
	}

	.btn-primary:not(:disabled):not(.disabled):active:focus,
	.btn-primary:not(:disabled):not(.disabled).active:focus,
	.show>.btn-primary.dropdown-toggle:focus {
		box-shadow: none;
	}

	.pf-block {
		padding: 120px 0 100px;
		font-size: 18px;
	}

	.pf-block-title {
		font-weight: 800;
	}

	.pf-block-line {
		background: #E87E04;
		width: 100px;
		height: 3px;
		margin: 30px auto;
	}

	.pf-block-text {
		text-align: justify;
		font-size: 20px;
	}

	.hidden {
		display: none;
	}

	.page-container {
		position: absolute;
		top: 100%;
		width: 100%;
	}

	.top-section {
		background: url('../../public/images/cover_blurred.jpg') no-repeat;
		background-size: cover;
		position: absolute;
		height: 100%;
		width: 100%;

		.overlay {
			background-color: rgba(60, 77, 95, 0.5);
			background-image: url('../../public/images/pattern.png');
			background-repeat: repeat;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;
		}
	}

	.intro-image-block {
		position: relative;

		&__image {
			width: 300px;
			height: auto;
			border-radius: 50%;
			box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);

			&--animated {
				animation-name: gates-open;
				animation-duration: 1s;
				animation-fill-mode: forwards;
			}
		}		
	}

	.intro {
		text-align: center;
		margin-top: 10%;
	}

	.intro-text-block {
		color: white;

		&__text {
			padding-top: 15px;
		}

		&__middle-text {
			font: 700 40px/1.2 Raleway, sans-serif;
			text-transform: uppercase;
			letter-spacing: 5px;
			margin: 5px 0 5px;
			font-weight: 800;
			display: block;
			padding: 15px 0;
		}
	}

	.scroll-down {
		width: 50px;
		height: 50px;
		color: white;
		position: absolute;
		bottom: 40px;
		left: calc(50% - 25px);
		text-align: center;
		border: 2px solid #fff;
		border-radius: 50%;
		cursor: pointer;

		span {
			position: relative;
			-webkit-animation-name: drop;
			-webkit-animation-duration: 1s;
			-webkit-animation-timing-function: linear;
			-webkit-animation-delay: 0s;
			-webkit-animation-iteration-count: infinite;
			-webkit-animation-play-state: running;
			animation-name: drop;
			animation-duration: 1s;
			animation-timing-function: linear;
			animation-delay: 0s;
			animation-iteration-count: infinite;
			animation-play-state: running;
		}
	}

	.main-menu {
		background-color: white;

		.navbar-brand {
			font-weight: 600;
			float: left;
			height: 50px;
			padding: 15px 15px;
			line-height: 20px;
			font-weight: 700;
			font-size: 22px;
			font-family: Ubuntu;
		}

		.navbar-nav {
			a {
				font-size: 11px;
				letter-spacing: 3px;
				padding: 10px 15px;
				text-transform: uppercase;
				font-family: Raleway, sans-serif;
				line-height: 20px;
				font-weight: 300;
				color: black;
			}

			a:hover {
				color: #E7746F;
			}

			li.active a {
				color: #E7746F;
			}
		}

		&.sticky {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 1;

			&+.about-me-section {
				padding-top: 186px;
			}
		}
	}

	.about-me,
	.portfolio {
		background: #f5f5f5;
		text-align: center;
	}

	.portfolio-item{
		&__link{
			flex-basis: 21%;
			margin: 5px;
		}
	}

	.portfolio {
		.portfolio-container {
			.row {
				overflow-y: auto;
				overflow-x: hidden;
				max-height: 440px;
				justify-content: space-around;
				display: flex;
				flex-wrap: wrap;
			}
		}
	}

	.about-me {
		.download-resume {
			padding: 20px 0;
		}
	}

	.experience {
		background: linear-gradient(to bottom, #ffffff 0%, #f4f5f6 50%, #ffffff 100%);
		text-align: center;

		.timeline {
			position: relative;

			&::before {
				position: absolute;
				content: "";
				left: 50%;
				width: 2px;
				background-color: rgb(238, 238, 238);
				height: calc(100% - 50px);
				top: 10px;
			}

			.timeline-item {
				width: 50%;
				position: relative;
				margin: 10px 0 70px;

				.dates-image {
					position: absolute;
					left: calc(100% - 75px);

					background-color: white;
					width: 150px;
					min-width: 150px;
					height: 150px;
					border-radius: 50%;
					border: 1px solid rgb(238, 238, 238);
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.date-from,
					.date-to {
						font-size: 18px;
					}

					p {
						margin: 0;
					}
				}

				&-right {
					left: 50%;
					display: flex;
					flex-direction: row-reverse;

					.dates-image {
						left: calc(-75px);
					}
				}

				.position-description {
					padding: 0 5px;
				}

				.text {
					color: rgb(87, 100, 114);
					width: calc(100% - 75px);
				}

				.company-title,
				.company-position {
					font-weight: 700;
					font-size: 18px;
				}

				.company-position {
					color: #E87E04;
				}
			}
		}
	}

	.contact-me {
		color: #f4f5f6;
		text-align: center;
		background-attachment: fixed;
		padding-left: 0;
		padding-right: 0;

		.background-mask {
			background-color: rgba(60, 77, 95, 0.5);
		}

		.pf-block-subtitle {
			text-align: center;
		}

		.three-items-block {
			margin-top: 30px;
			text-shadow: 1px 1px 2px black;

			a {
				color: #f4f5f6;
			}
		}

		.leave-me-a-message {
			text-shadow: 1px 1px 2px black;
			margin: 30px auto 0;
			text-transform: uppercase;
			font-size: 20px;
			font-family: Raleway, sans-serif;
		}

		textarea {
			resize: none;
		}

		.social-icons {
			.contact-icon {
				width: 50px;
				height: 50px;
				display: inline-block;
				border-radius: 8px;

				&:hover {
					box-shadow: 1px 1px 4px gray;
				}
			}

			.row {
				justify-content: space-around;
			}

			a {
				color: white;
			}
		}

		.upwork-icon {
			text-shadow: none;
			background: url('../../public/images/upwork-contact-icon.png') no-repeat;
			background-size: contain;
			width: 40px;
			height: 30px;
			display: inline-block;
			position: relative;
			top: 10px;
		}

		// .upwork-icon:hover {
		// 	background: url('../../public/images/upwork-icon-hover.png') no-repeat;
		// 	background-size: contain;
		// }
	}

	footer {
		text-align: center;
		background-color: #2c3e50;
		color: white;
		height: 100px;
		display: flex;
		align-items: center;

		p {
			flex: 1;
			margin: 0;
			font-size: 18px;
		}
	}
}

/*fancy box styling*/

.quick-view-container {
	background: rgba(10, 10, 10, .85);
}

.quick-view-content {
	bottom: 0;
	height: calc(100% - 40px);
	left: 0;
	margin: auto;
	max-height: 850px;
	max-width: 1380px;
	position: absolute;
	right: 0;
	top: 0;
	width: calc(100% - 40px);
}

/*--------------------------------   Carousel  -----------------------------*/

.carousel {
	&__container {
		.owl-stage {
			.owl-item {
				.carousel__image {
					max-height: 600px;
					width: auto;
					margin: 0 auto;
					max-width: 100%;
					height: auto;
				}
			}
		}
	}
}

.modal {
	.modal-dialog {
		max-width: 902px;

		.modal-content {
			display: block;

			.button-absolute {
				display: none;
			}

			.modal-header {
				border-bottom: none;
				justify-content: center;
			}
		}
	}
}

.owl-carousel {
	max-width: 500px;
	padding: 15px;
}

.modal-content {
	&__left {
		width: 500px;
	}

	&__right {
		width: 400px;
		margin-top: 36px;
		padding: 0 15px;
	}

	.modal-title {
		font-size: 1.25rem;
	}

	.left-block {
		&__slider {
			border-top: 1px solid #dee2e6;
			border-right: 1px solid #dee2e6;
			overflow: hidden;

			.zoom img:hover {
				cursor: zoom-in;
			}
		}
	}

	.right-block {
		&__header {
			text-align: center;
			font-size: 1.25rem;
		}

		&__content {
			text-align: justify;

			.responsibilities {
				&__list {
					text-align: left;
					padding-left: 0;

					li {
						margin-left: 20px;
					}
				}
			}
		}
	}

	.close {
		&__button {
			left: -15px;
			position: relative;
			top: 12px;
			border: 1px solid black;
			width: 26px;
			height: 26px;
			border-radius: 50%;
			box-shadow: 1px 1px grey;
		}
	}
}

.technology-tag {
	background: darkblue;
	color: white;
	padding: 0 8px;
	border-radius: 100px;
	margin-right: 5px;
	box-shadow: 1px 1px 3px 0px grey;
	display: inline-block;
	user-select: none;
	margin-bottom: 5px;
}

@import 'home/top-section.less';
@import 'home/about-me-section.less';
@import 'home/main-menu.less';
@import 'home/projects-section.less';
@import 'home/project-item.less';
@import 'home/contacts-section.less';
@import 'error-page.less';

.pf-block {
	width: 800px;
	margin: 0 auto;

	&.max{
		max-width: 1024px;
		width:unset;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.pf-block-text {
	text-align: justify;
	padding: 0 10px;
}

@import 'home/media.less';

.btn {
	display: inline-block;
	
	user-select: none;
	font-size: 1rem;
	line-height: 1.5;

	.button-content{
		display: flex;

		.loading-icon{
			display: block;
			width: 15px;
			height: 15px;
		}
	}
}

.text-danger {
	color: red;
}