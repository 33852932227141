//main: "../main.less"

.portfolio {
	.company-name {
		&:not(:last-child)::after {
			content: "|";
			position: relative;
			left: 10px;
		}

		&:hover::after {
			color: black;
		}

		&.active::after {
			color: black;
		}
	}

	.companies-row {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		font-size: 16px;

		
		.company-name {
			font-weight: 500;
			margin: 0 10px;

			&:hover {
				color: #E87E04;
				cursor: pointer;
			}

			&.active {
				color: #E87E04;
			}
		}
	}

	.technologies-row {

		padding: 10px 0;

		.technology-tag {
			&.active {
				background-color: orange;
			}

			&:hover {
				cursor: pointer;
				background-color: orange;
			}

			.counter {
				background-color: white;
				color: black;
				border-radius: 50%;
				font-size: 13px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 25px;
				height: 25px;
				position: relative;
				right: -8px;
				box-shadow: 1px 1px 1px black;
				border: 1px solid black;
			}
		}
	}


	
}