//main: "../main.less"

.contact-me {
	background-image: url(../../public/images/contact_us_background_blurred.jpg);

	.three-items-block {
		display: flex;
		justify-content: space-around;


		.contact-item {}
	}

	.social-icons {
		margin-top: 60px;
		display: flex;
		justify-content: right;

		.social-icon {
			margin-left: 10px;
		}

		.facebook {
			background: url(../../public/images/facebook-contact-icon.png) no-repeat;
		}

		.upwork {
			background: url(../../public/images/upwork-contact-icon.png) no-repeat;
		}

		.linkedin {
			background: url(../../public/images/linkedin-contact-icon.png) no-repeat;
		}
	}

	.form-control-input,
	.form-control-text-area {
		display: block;
		width: 100%;

		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		transition: border-color 0.15s
	}

	.form-control-input {
		height: calc(1.5em + 0.75rem + 2px);
	}

	.name-email-block {
		display: flex;
		justify-content: space-around;

		.input-block {
			flex-basis: 250px;
			flex-shrink: 1;
		}
	}

	.message-block {
		display: flex;
		justify-content: space-around;

		.message {
			flex-basis: 450px;
			flex-shrink: 1;
		}
	}

	.btn {
		display: inline-block;
		font-weight: 400;
		color: #212529;
		text-align: center;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: transparent;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		border-radius: 0.25rem;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}

	.button-row {
		padding: 20px 0 10px;

		.btn-outline-light {
			color: #f8f9fa;
			border-color: #f8f9fa;

			&:hover {
				color: #212529;
				background-color: #f8f9fa;
				border-color: #f8f9fa;
				cursor: pointer;
			}

			&:focus,
			&:active {
				box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
			}
		}
	}

	.message-form {
		.label {
			display: inline-block;
			margin: 10px;
		}
	}

	.alert {
		position: fixed;
		top: 100px;
		right: 25px;
		padding: 20px;
		border-radius: 10px;

		&-success {
			background-color: #2bcb2b;
		}

		&-danger {
			background-color: red;
		}

		.close {
			width: 20px;
			height: 20px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			font-size: 20px;

			&::after{
				content: '×';
				position: relative;
				top: -2px;
				right: -1px;
			}

			&:hover{
				background: white;
				color: black;
				border-radius: 50%;
			}
		}
	}

	.recapcha{
		display: flex;
		justify-content: center;
		padding-top: 20px;
	}
}